/**
 * Created by koko on 08/01/16.
 */
'use strict';

$.ajaxSetup({
    headers: { 'X-CSRF-Token' : $('meta[name=csrf_token]').attr('content') }
});

$(function() {
    $(document).on('click', '.delete-language', function(event) {
        event.preventDefault();
        var el = $(this);
        swal({
            title: el.data('title'),
            text: el.data('description'),
            type: "warning",
            showCancelButton: true,
            closeOnConfirm: false
        }, function () {
            $.get(BASE_URL + 'language/delete', {
                id: el.data('id')
            }, function (response) {
                if (response.success == 'ok') {
                    swal.close();
                    var parent = el.parent();
                    parent = parent.parent();
                    parent.fadeOut(function () {
                        parent.empty().remove();
                    });
                } else {
                    swal("Error...", "System ERROR! Please reload!", "error");
                }
            }, 'json');
        });
    });

    $(document).on('click', '.delete-page', function (event) {
        event.preventDefault();
        var el = $(this);
        swal({
            title: el.data('title'),
            text: el.data('description'),
            type: "warning",
            showCancelButton: true,
            closeOnConfirm: false
        }, function () {
            $.get(BASE_URL + 'page/delete', {
                id: el.data('id')
            }, function (response) {
                if (response.success == 'ok') {
                    swal.close();
                    var parent = el.parent();
                    parent = parent.parent();
                    parent.fadeOut(function () {
                        parent.empty().remove();
                    });
                } else {
                    swal("Error...", "System ERROR! Please reload!", "error");
                }
            }, 'json');
        });
    });

    $(document).on('click', '.delete-news', function (event) {
        event.preventDefault();
        var el = $(this);
        swal({
            title: el.data('title'),
            text: el.data('description'),
            type: "warning",
            showCancelButton: true,
            closeOnConfirm: false
        }, function () {
            $.get(BASE_URL + 'news/delete', {
                id: el.data('id')
            }, function (response) {
                if (response.success == 'ok') {
                    swal.close();
                    var parent = el.parent();
                    parent = parent.parent();
                    parent.fadeOut(function () {
                        parent.empty().remove();
                    });
                } else {
                    swal("Error...", "System ERROR! Please reload!", "error");
                }
            }, 'json');
        });
    });

    $(document).on('click', '.delete-menu', function (event) {
        event.preventDefault();
        var el = $(this);
        swal({
            title: el.data('title'),
            text: el.data('description'),
            type: "warning",
            showCancelButton: true,
            closeOnConfirm: false
        }, function () {
            $.get(BASE_URL + 'delete/menu', {
                id: el.data('id')
            }, function (response) {
                if (response.success == 'ok') {
                    swal.close();
                    var parent = el.parent();
                    parent = parent.parent();
                    parent.fadeOut(function () {
                        parent.empty().remove();
                    });
                } else {
                    swal("Error...", "System ERROR! Please reload!", "error");
                }
            }, 'json');
        });
    });

    var uploader = $('#uploader');
    if (uploader.length > 0) {
        uploader.fileupload({
            dataType: 'json',
            singleFileUploads: false,
            dropZone: $('#dropzone'),
            url: BASE_URL + 'upload',
            add: function (e, data) {
                data.submit();
            },
            done: function (e, data) {
                $('.create-progress .progress-bar')
                    .addClass('progress-bar-success')
                    .removeClass('progress-bar-warning');
                $.each(data.result.files, function (index, file) {
                    var tr = $('<tr>').css({display: 'none'});
                    var thumb = $('<td>');
                    /**
                     * @typedef {string} file.isImage
                     * @typedef {string} file.thumbnailUrl
                     */
                    if (file.isImage) {
                        thumb.append(
                            $('<img>').addClass('img-thumbnail').prop('src', file.thumbnailUrl)
                        );
                    }
                    if(file.filedata.post_id > 0) {
                        tr.append(
                            $('<td>').append(
                                $('<input type="checkbox" value="1" name="header_image[' + file.filedata.id + ']">').addClass('header-image')
                            )
                        );
                    }
                    tr.append(thumb)
                        .append(
                            $('<td>').append(
                                $('<input>').prop({
                                    type: 'text',
                                    name: 'dummyFiles[]'
                                }).addClass('form-control filename').val(file.url)
                            )
                        )
                        .append($('<td>').html(file.size))
                        .append($('<td>').html('just now'));
                    $('.files-collection').prepend(tr);
                    tr.slideDown();
                });
            },
            progressall: function (e, data) {
                var progress = parseInt(data.loaded / data.total * 100, 10);
                $('.create-progress .progress-bar').css(
                    'width',
                    progress + '%'
                );
                $('#uploader-progress').text(progress + '%');
            }
        }).bind('fileuploadstart', function () {
            $('.create-progress').addClass('active');
        });

        $(document).bind('dragover', function (e) {
            var dropZone = $('#dropzone'),
                timeout = window.dropZoneTimeout;
            if (!timeout) {
                dropZone.addClass('in');
            } else {
                clearTimeout(timeout);
            }
            var found = false,
                node = e.target;
            do {
                if (node === dropZone[0]) {
                    found = true;
                    break;
                }
                node = node.parentNode;
            } while (node != null);
            if (found) {
                dropZone.addClass('hover');
            } else {
                dropZone.removeClass('hover');
            }
            window.dropZoneTimeout = setTimeout(function () {
                window.dropZoneTimeout = null;
                dropZone.removeClass('in hover');
            }, 100);
        });
        $(document).on('click', '.filename', function () {
            $(this).select();
        });
    }

    $(document).on('click', '.header-image', function() {
        $('.header-image').prop('checked', false);
        $(this).prop('checked', true);
    });

    $(document).on('click', '.delete-asset', function (event) {
        event.preventDefault();
        var el = $(this);
        swal({
            title: el.data('title'),
            text: el.data('description'),
            type: "warning",
            showCancelButton: true,
            closeOnConfirm: false
        }, function () {
            $.get(BASE_URL + 'asset/delete', {
                id: el.data('id')
            }, function (response) {
                if (response.success == 'ok') {
                    swal.close();
                    var parent = el.parent();
                    parent = parent.parent();
                    parent.fadeOut(function () {
                        parent.empty().remove();
                    });
                } else {
                    swal("Error...", "System ERROR! Please reload!", "error");
                }
            }, 'json');
        });

    });

    if ($('#pages-new-data').length > 0) {
        configureEditor('pages-new-data', 'big');
    }

    if ($('#post-new-text1').length > 0) {
        configureEditor('post-new-text1', 'normal');
    }

    if ($('#post-new-text2').length > 0) {
        configureEditor('post-new-text2', 'normal');
    }

    if ($('#footer-en').length > 0) {
        configureEditor('footer-en', 'small');
    }

    if ($('#footer-lv').length > 0) {
        configureEditor('footer-lv', 'small');
    }

    if ($('#about-en').length > 0) {
        configureEditor('about-en', 'small');
    }

    if ($('#about-lv').length > 0) {
        configureEditor('about-lv', 'small');
    }

    $(document).on('change', '#menu-add-module', function () {
        var el = $(this);
        var formMid = $('.form-group.mid');
        if (el.val() == 'page') {
            formMid.show();
            $.get(BASE_URL + 'ajax/module', {
                module: el.val(),
                lang: $('#curlang').val()
            }, function (response) {
                if (response.success == 'ok') {
                    /**
                     * @typedef {string} response.html
                     */
                    formMid.html(response.html);
                }
            }, 'json');
        } else {
            formMid.html('<div class="col-md-1 col-md-offset-4 help-block loading"><i class="glyphicon glyphicon-refresh fa-spin"></i></div>').hide();
        }
    });


    var menuSortable = $('tbody', $('#menu-sortable'));
    var curLoading = $('.' + CUR_LANG + '-loading');
    if (menuSortable.length > 0) {
        menuSortable.sortable({
            handle: '.handle',
            axis: 'y',
            update: function () {
                curLoading.show();
                $.get(BASE_URL + 'ajax/save-menu-order', {
                    lang: CUR_LANG,
                    order: menuSortable.sortable('toArray')
                }, function (response) {
                    if (response.success == 'ok') {
                        curLoading.hide();
                    } else {
                        var errorMsg = [];
                        $.each(response.errors, function () {
                            errorMsg.push(this);
                        });
                        swal('Kļūda!', errorMsg.join('\n'), 'error');
                    }
                    curLoading.hide();
                }, 'json');
            }
        });
    }

    var menuEditForm = $('#menu-edit-form');
    $(document).on('click', '.edit-menu', function (event) {
        event.preventDefault();
        var el = $(this);
        var menuId = el.data('id');
        curLoading.show();
        $.get(BASE_URL + 'ajax/get-menu-record', {
            id: menuId
        }, function (response) {
            if (response.success == 'ok') {
                /**
                 * @typedef {object} response.item
                 * @typedef string response.item.perm
                 * @typedef int response.item.show_in_menu
                 */
                $('input[name=id]', menuEditForm).val(response.item.id);
                $('input[name=title]', menuEditForm).val(response.item.title);
                $('input[name=perm]', menuEditForm).val(response.item.perm);
                $('select[name=module] option', menuEditForm).prop('selected', false);
                $('select[name=module] > option[value=' + response.item.module + ']', menuEditForm).prop('selected', 'selected');

                $('select[name=language] option', menuEditForm).prop('selected', false);
                $('select[name=language] > option[value=' + response.item.language + ']', menuEditForm).prop('selected', 'selected');

                if (response.item.show_in_menu == 1) {
                    $('input[name=show_in_menu]', menuEditForm).prop('checked', 'checked');
                } else {
                    $('input[name=show_in_menu]', menuEditForm).prop('checked', false);
                }

                if (response.item.status == 1) {
                    $('input[name=status]', menuEditForm).prop('checked', 'checked');
                } else {
                    $('input[name=status]', menuEditForm).prop('checked', false);
                }

                var formEdit = $('.mid-edit', menuEditForm);

                if (response.item.module == 'page') {
                    $.get(BASE_URL + 'ajax/module', {
                        module: el.val(),
                        lang: $('#curlang').val(),
                        id: menuId
                    }, function (response) {
                        if (response.success == 'ok') {
                            /**
                             * @typedef {string} response.html
                             */
                            formEdit.html(response.html).show();
                        }
                        curLoading.hide();
                        $('.edit-menu-form').slideDown();
                    }, 'json');
                } else {
                    formEdit.hide();
                    curLoading.hide();
                    $('.edit-menu-form').slideDown();
                }

            } else {
                curLoading.hide();
                swal('System error!', 'Please refresh your browser', 'error');
            }
        }, 'json');
    });

    $(document).on('change', '#menu-edit-form select[name=module]', function () {
        var el = $(this);
        var formEdit = $('.mid-edit', menuEditForm);
        if (el.val() == 'page') {
            formEdit.show();
            $.get(BASE_URL + 'ajax/module', {
                module: el.val(),
                lang: $('#curlang').val()
            }, function (response) {
                if (response.success == 'ok') {
                    /**
                     * @typedef {string} response.html
                     */
                    formEdit.html(response.html);
                }
            }, 'json');
        } else {
            formEdit.html('<div class="col-md-1 col-md-offset-4 help-block loading"><i class="glyphicon glyphicon-refresh fa-spin"></i></div>').hide();
        }
    });

    $(document).on('click', '#cancel-edit-menu', function (event) {
        event.preventDefault();

        $('.edit-menu-form').slideUp();
    });

    $(document).on('click', '#save-edit-menu', function (event) {
        event.preventDefault();
        $.post(BASE_URL + 'save-menu', menuEditForm.serialize(), function (response) {
            /**
             * @typedef object response.item
             * @typedef int response.item.module_id
             */
            if (response.success == 'ok') {
                var itemRow = $('#item-' + response.item.id);
                $('td:nth-child(2)', itemRow).html(response.item.title);
                if (response.item.module == 'page') {
                    $('.edit-text', itemRow).prop('href', BASE_URL + response.item.module + '/edit/' + response.item.module_id);
                } else {
                    $('.edit-text', itemRow).prop('href', BASE_URL + response.item.module);
                }

                $('.edit-menu-form').slideUp();
                if (response.item.status == 0) {
                    $('#item-' + response.item.id).addClass('danger');
                } else {
                    $('#item-' + response.item.id).removeClass('danger');
                }
            } else {
                var errorMsg = [];
                $.each(response.errors, function () {
                    errorMsg.push(this);
                });
                swal('Kļūda!', errorMsg.join('\n'), 'error');
            }
        }, 'json');
    });

    var sortableBlocks = $('#sortable-blocks');
    if(sortableBlocks.length > 0) {
        sortableBlocks.sortable({
            placeholder: 'block-placeholder',
            // containment: "parent",
            // grid: [204, 204],
            helper: "clone",
            items: '.block-sortable'
        });
    }

    $(document).on('click', '#save-block-order', function (event) {
        event.preventDefault();
        var postData = sortableBlocks.sortable('serialize');
        var el = $(this);
        el.addClass('disabled');
        $.post(BASE_URL + 'block/sort', postData, function (response) {
            el.removeClass('disabled');
        });
    });
});

function configureEditor(id, type) {
    if(CKEDITOR.instances[id]) return;
    type = type || 'small';
    var buttons = [
        {
            name: 'document',
            groups: ['mode', 'document', 'doctools'],
            items: ['Source'/*, '-', 'Save', 'NewPage', 'Preview', 'Print', '-', 'Templates'*/]
        },
        {
            name: 'clipboard',
            groups: ['clipboard', 'undo'],
            items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo']
        },
        {
            name: 'editing',
            groups: ['find', 'selection'/*, 'spellchecker'*/],
            items: ['Find', 'Replace', '-', 'SelectAll', '-', 'Scayt']
        },
        // { name: 	forms', items: [ 'Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField' ] },
        // '/',
        {
            name: 'basicstyles',
            groups: ['basicstyles', 'cleanup'],
            items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat']
        },
        {
            name: 'paragraph',
            groups: ['list', 'indent', 'blocks', 'align', 'bidi'],
            items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl']
        },
        {name: 'links', items: ['Link', 'Unlink', 'Anchor']},
        {
            name: 'insert',
            items: ['Image', 'Flash', 'Table', 'HorizontalRule', /*'Smiley',*/ 'SpecialChar', /*'PageBreak', */'Iframe']
        },
        // '/',
        {name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize']},
        {name: 'colors', items: ['TextColor', 'BGColor']},
        {name: 'tools', items: ['Maximize', 'ShowBlocks']},
        {name: 'other', items: ['charcounter']},
        // { name: 'about', items: [ 'About' ] },
        {name: 'others', items: ['-']}
    ];
    switch (type) {
        case 'small':
            CKEDITOR.replace(id, {
                toolbar: buttons,
                height: 200,
                allowedContent: true,
                skin: 'minimalist',
                language: 'lv'
            });
            break;
        case 'normal':
            CKEDITOR.replace(id, {
                toolbar: buttons,
                height: 400,
                allowedContent: true,
                skin: 'minimalist',
                language: 'lv'
            });
            break;
        case 'big':
            CKEDITOR.replace(id, {
                toolbar: buttons,
                height: 600,
                allowedContent: true,
                skin: 'minimalist',
                language: 'lv'
            });
            break;
    }
}